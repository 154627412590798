<template>
   <div>
    <PageHeader pagetitle="GAD" :img="img"/>   
    <v-data-table
    :headers="headers"
    :items="gad"
    :items-per-page="15"
    class="elevation-1"
    dense
    >     
    <template #item.Url="{ item }">
        <a target="_blank" :href="$api_url+item.Url">
            {{ item.Name }}
        </a>
    </template>

    </v-data-table>   
    
  </div>
  
 
</template>

<script>

import PageHeader from '@/components/PageHeader'

export default {
  name : 'GAD',
  components: {
    PageHeader
  },
  data () {
    return {
      img : 'https://cdn.vuetifyjs.com/images/backgrounds/vbanner.jpg',
      gad:[],
      headers: [],
      tablename:this.$vnode.tag,
      items:[]
    
    }
  },
  mounted()  {
      
    this.tablename=this.tablename.replace(/vue-component-\d+-/i, '').toLowerCase();
   
    this.headers=[ 
    { text: 'NAME',
        value: 'Name',
        align: 'start',
      },
      { text: 'DOCUMENT NAME',
        value: 'Url',
        align: 'start',
      },

    ]
    this.getAllRecords(`/getAllRecords/gad/where ID > 0/CreatedDate desc`,'gad');
     
  },

  watch: {
    
  }, 

  methods : {
  getAllRecords:function(query,table){
        //alert(query);       
        this.$axios.get( this.$api_url+query,{
            
          params: {
               
            }
        })
        .then(response => {
            //alert(Object.keys(response.data).length);
            if (Object.keys(response.data).length > 0) {
                
                if (table=='gad'){
                    console.log(response.data)
                    this.gad=response.data;
                   
                    //this.departmentDownloadables=response.data;
                }
                
              }
        })
        .catch(error => { console.log(error)})
        
    },  

  }


}
</script>

<style>
  tbody tr:nth-of-type(odd) {
   background-color: rgba(0, 0, 0, .05);
 }
</style>